import React from "react"

import Layout from "components/Layout/en"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./PaintScanner.svg"
import { Table } from "react-bootstrap"
import { MdDone } from "react-icons/md"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Use the MoonVision PaintScanner to automatically inspect painted
        surfaces for defects and quality properties. By using visual data
        generated by cameras, you will be informed at an early stage whether
        your quality characteristics have been met and/or whether there is a
        need for action.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automated quality inspection of painted surfaces</li>
        <li>Benefit from consistent quality and reduced lead times</li>
        <li>Detection and measurement with cameras & software</li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual Output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="PaintScanner"
        title="Quality inspection of painted surfaces in real time"
        subtitle="Inspection of painted surfaces with the most precise technology"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated paint testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">
                  Detection of different defects on painted surfaces:
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Poor covering</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Paint Bubbles caused by Air/ Dust</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Crater</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Dent</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Scratches</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Range of Application:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Automotive</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Furniture</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            The MoonVision PaintScanner was developed especially for the surface
            inspection of painted components, e.g. in the automotive industry.
            New technologies such as machine learning are used to detect even
            the smallest deviations which are difficult for employees to detect
            in a short period of time. Test standards can be defined
            individually according to your wishes. The result of the automated
            quality inspection can be used, for example, to evaluate components
            differently or to derive statistics.
          </p>
          <p>
            The artificial intelligence used outshines existing approaches with
            regard to inspection accuracy - the evaluation process is fully
            automated. You profit from the time savings.
          </p>
          <p>
            Several cameras and exposure types and sources are used to achieve
            the highest possible accuracy. The system detects those types of
            defects that are visible to the human eye.
          </p>
          <p>
            The MoonVision PaintScanner can be used at different production
            steps from the incoming goods inspection to the outgoing goods
            inspection. Due to its self-sufficient design, the software can be
            integrated into a running production process or existing ERP
            systems.
          </p>
          <p>At the end of the process, results can also be:</p>
          <ul>
            <li>
              Displayed visually(on a monitor/alarms/storage of jpg files)
            </li>
            <li>Transmitted in tabular form</li>
            <li>Passed on to management information systems via APIs</li>
            <li>Used for process control via APIs</li>
          </ul>
          <p>
            Available where you need it - The hardware for using the MoonVision
            PaintScanner can be installed directly at your site, but can also be
            made available online. No mather which option you choose, you have
            access to the MoonVision Toolbox and can make adjustments at any
            time, e.g. for parameter adaptation and personalisation.
          </p>
          <h3>Simple to use</h3>
          <p>
            Due to the software and hardware design, the optimization and
            implementation of the MoonVision PaintScanner can be realized with
            reasonable effort. After the examination of some of your components
            you know which quality you can count on and whether further
            preparatory measures are needed to increase the recognition
            accuracy.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
